import { AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import store from '@/store';
import { createAxiosInstance } from '@/api/axios';

const client = createAxiosInstance(process.env.VUE_APP_API_URL_ACCOUNT_BACKEND);

axiosRetry(client, { retries: 3 });

const request = async <T>(callback: (headers: { Authorization: string }) => T) => {
    await store.dispatch('auth/isSignIn');
    return callback({
        Authorization: store.getters['auth/idToken'],
    });
};

export const post = async <T>(url: string, data?: unknown): Promise<AxiosResponse<T> | undefined> =>
    await request(
        async (headers) =>
            await client.post(url, data, {
                headers,
            })
    );

export const get = async <T>(url: string, params?: unknown): Promise<AxiosResponse<T> | undefined> =>
    await request(
        async (headers) =>
            await client.get(url, {
                headers,
                params,
            })
    );

export const put = async <T>(url: string, data?: unknown): Promise<AxiosResponse<T> | undefined> =>
    await request(
        async (headers) =>
            await client.put(url, data, {
                headers,
            })
    );

export const del = async <T>(url: string): Promise<AxiosResponse<T> | undefined> =>
    await request(
        async (headers) =>
            await client.delete(url, {
                headers,
            })
    );
