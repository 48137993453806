import Vue from 'vue';
import { VNode } from 'vue';
import { DirectiveBinding, DefaultMethods } from 'vue/types/options';

interface ClickOutsideHTMLElement extends HTMLElement {
    clickOutsideEvent?: (event: Event) => void;
}

Vue.directive('click-outside', {
    bind(el: ClickOutsideHTMLElement, binding: DirectiveBinding, vnode: VNode): void {
        el.clickOutsideEvent = (event: Event): void => {
            if (!(el === event.target || el.contains(event.target as Node)) && binding.expression) {
                (vnode.context as Vue & DefaultMethods<Vue>)[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind(el: ClickOutsideHTMLElement): void {
        if (el.clickOutsideEvent != null) {
            document.body.removeEventListener('click', el.clickOutsideEvent);
        }
    },
});
