import { GetterTree } from 'vuex';
import { AuthState, Admin } from './types';
import { RootState } from '../types';

export const getters: GetterTree<AuthState, RootState> = {
    admin(state): Admin | undefined {
        return state.admin;
    },
    isAdmin(state): boolean {
        return state.admin?.role === '1' ? true : false;
    },
    authenticated(state): boolean {
        return state.authenticated;
    },
    loginId(state): string {
        return state.loginId;
    },
    idToken(state): string {
        return state.idToken;
    },
};
