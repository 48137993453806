import { AuthState } from './types';

export const getDefaultState: () => AuthState = () => {
    return {
        authenticated: false,
        loginId: '',
        idToken: '',
        admin: undefined,
    };
};

export const state = getDefaultState();
