import axios, { AxiosInstance } from 'axios';
import { outputLog } from '@/api/log';

export const createAxiosInstance = (baseURL?: string): AxiosInstance => {
    const client = axios.create({
        baseURL,
        headers: { 'Content-Type': 'application/json' },
        responseType: 'json',
    });
    client.interceptors.response.use(
        (response) => response,
        (error) => {
            try {
                if (axios.isAxiosError(error)) {
                    const config = error.config;
                    const logBody: Record<string, unknown> = {
                        message: 'axios error.',
                        method: config.method,
                        requestUrl: `${config.baseURL}${config.url}`,
                        location: window.location.href,
                    };
                    if (config.data) {
                        logBody.requestData = config.data;
                    }
                    if (error.response) {
                        logBody.responseStatus = error.response.status;
                        logBody.responseData = error.response.data;
                    }
                    outputLog(logBody);
                }
                // eslint-disable-next-line no-empty
            } catch (_) {}
            return Promise.reject(error);
        }
    );
    return client;
};
