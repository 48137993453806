import { ActionTree } from 'vuex';
import { AuthState, LoginParam, Admin } from './types';
import { RootState } from '../types';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import * as AccountClient from '@/api/accountApi/client';

export const actions: ActionTree<AuthState, RootState> = {
    authError({ commit }): void {
        commit('clearAuthState');
    },
    authClear({ commit }): void {
        commit('clearAuthState');
    },
    userLoadError({ commit }): void {
        commit('clearAuthState');
    },
    async isSignIn({ commit }): Promise<boolean> {
        return await Auth.currentSession()
            .then((data) => {
                commit('authUpdate', data);
                return true;
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch(async (_error: any) => {
                commit('clearAuthState');
                return false;
            });
    },
    async signIn({ commit, dispatch }, payload: LoginParam): Promise<void> {
        await Auth.signIn(payload.id, payload.password)
            .then(async (data: CognitoUser) => {
                commit('authSuccess', data);
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((_error: any) => {
                dispatch('authError');
            });
    },
    async signOut({ commit }): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await Auth.signOut({ global: false }).catch(async (_err: any) => {
            // TODO:エラーハンドリング
        });
        commit('clearAuthState');
    },
    async fetchAdminData({ state, commit }): Promise<void> {
        try {
            const response = await AccountClient.get<Admin[]>(`/adminuser/${state.loginId}`);
            if (response) {
                const adminUser: Admin = response.data[0];
                commit('adminLoaded', adminUser);
            } else {
                commit('clearAuthState');
            }
        } catch (error) {
            commit('clearAuthState');
        }
    },
};
