import { MutationTree } from 'vuex';
import { AuthState, Admin } from './types';
import { getDefaultState } from './state';

export const mutations: MutationTree<AuthState> = {
    setAuthenticated(state, flag: boolean) {
        state.authenticated = flag;
    },
    setLoginId(state, id: string) {
        state.loginId = id;
    },
    setIdToken(state, idToken: string) {
        state.idToken = idToken;
    },
    setAdmin(state, admin: Admin | undefined) {
        state.admin = admin;
    },
    authLoaded(state) {
        state.authenticated = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    authSuccess(state, payload: any) {
        state.authenticated = true;
        state.idToken = payload.signInUserSession.idToken.jwtToken;
        state.loginId = payload.username;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    authUpdate(state, payload: any) {
        state.authenticated = true;
        state.idToken = payload.idToken.jwtToken;
    },
    adminLoaded(state, admin: Admin) {
        state.admin = admin;
    },
    clearAuthState(state) {
        Object.assign(state, getDefaultState());
    },
};
