import axios from 'axios';
import store from '@/store';

const client = axios.create({
    baseURL: process.env.VUE_APP_API_URL_ISO_MASTER,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
});

export const outputLog = async (body: { [key: string]: unknown } | Map<string, unknown>): Promise<void> => {
    await client.post('/log', body, {
        headers: {
            Authorization: store.getters['auth/idToken'],
        },
    });
};
