import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@/directive/click-outside';
import { Amplify } from 'aws-amplify';

Vue.config.productionTip = false;

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Amplify.configure({
    Auth: {
        region: process.env.VUE_APP_AWS_AUTH_REGION,
        userPoolId: process.env.VUE_APP_AWS_AUTH_USER_POOL_ID,
        userPoolWebClientId: process.env.VUE_APP_AWS_AUTH_USER_POOL_WEB_CLIENT_ID,
        mandatorySignIn: true,
        storage: window.sessionStorage,
    },
    API: {
        endpoints: [
            {
                name: process.env.VUE_APP_API_URL_ACCOUNT_BACKEND_NAME,
                endpoint: process.env.VUE_APP_API_URL_ACCOUNT_BACKEND,
                region: process.env.VUE_APP_AWS_API_REGION,
            },
            {
                name: process.env.VUE_APP_API_URL_CONTENTS_BACKEND_NAME,
                endpoint: process.env.VUE_APP_API_URL_CONTENTS_BACKEND,
                region: process.env.VUE_APP_AWS_API_REGION,
            },
        ],
    },
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

Vue.prototype.$browserBackFlg = false;
history.replaceState(null, '', null);
window.addEventListener('popstate', function () {
    Vue.prototype.$browserBackFlg = true;

    window.setTimeout(() => {
        Vue.prototype.$browserBackFlg = false;
    }, 500);
});
