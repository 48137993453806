import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import store from '@/store/index';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/Login.vue'),
    },
    {
        path: '/',
        name: 'dashboard',
        component: () => import('../views/Dashboard.vue'),
        meta: { requiresAuth: true },
    },
    //ContentsCreator Menu
    {
        path: '/contents',
        component: () => import('../views/contents/ContentsCreator.vue'),
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                redirect: 'article_list',
            },
            {
                path: 'article_list',
                name: 'article_list',
                component: () => import('../views/contents/ArticleList.vue'),
            },
            {
                path: 'article_list/create',
                name: 'create_article',
                component: () => import('../views/contents/ArticleInfo.vue'),
            },
            {
                path: 'article_list/:id',
                name: 'article_info',
                component: () => import('../views/contents/ArticleInfo.vue'),
            },
        ],
    },
    //CourseDesigner Menu
    {
        path: '/course',
        component: () => import('../views/course/CourseDesigner.vue'),
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                redirect: 'learningitem',
            },
            {
                path: 'learningitem',
                component: () => import('../views/course/learningitem/LearningItem.vue'),
                children: [
                    {
                        path: '',
                        name: 'learningitem_init',
                        component: () => import('../views/course/learningitem/LearningItemInit.vue'),
                    },
                    {
                        path: 'company_info/:companyCode',
                        name: 'learningitem_company_info',
                        component: () => import('../views/course/learningitem/CompanyInfo.vue'),
                    },
                    {
                        path: 'course_info/:companyCode/:courseId',
                        name: 'learningitem_course_info',
                        component: () => import('../views/course/learningitem/CourseInfo.vue'),
                    },
                    {
                        path: 'module_info/:companyCode/:courseId/:moduleId',
                        name: 'learningitem_module_info',
                        component: () => import('../views/course/learningitem/ModuleInfo.vue'),
                    },
                    {
                        path: 'lesson_info/:companyCode/:courseId/:moduleId/:lessonId',
                        name: 'learningitem_lesson_info',
                        component: () => import('../views/course/learningitem/LessonInfo.vue'),
                    },
                    {
                        path: 'topic_info/:companyCode/:courseId/:moduleId/:lessonId/:topicId',
                        name: 'learningitem_topic_info',
                        component: () => import('../views/course/learningitem/TopicInfo.vue'),
                    },
                ],
            },
            {
                path: 'exam',
                component: () => import('../views/course/exam/Exam.vue'),
                children: [
                    {
                        path: '',
                        redirect: 'exam_list',
                    },
                    //Exam
                    {
                        path: 'exam_list',
                        name: 'exam_list',
                        component: () => import('../views/course/exam/ExamList.vue'),
                    },
                    {
                        path: 'exam_list/new',
                        name: 'exam_new',
                        component: () => import('../views/course/exam/ExamInfo.vue'),
                    },
                    {
                        path: 'exam_list/:examId',
                        name: 'exam_info',
                        component: () => import('../views/course/exam/ExamInfo.vue'),
                    },
                    //Question
                    {
                        path: 'question_list',
                        name: 'question_list',
                        component: () => import('../views/course/exam/QuestionList.vue'),
                    },
                    {
                        path: 'question_list/:questionId',
                        name: 'question_info',
                        component: () => import('../views/course/exam/QuestionInfo.vue'),
                    },
                    //Ranking
                    {
                        path: 'ranking_info',
                        name: 'ranking_info',
                        component: () => import('../views/course/exam/RankingInfo.vue'),
                    },
                ],
            },
        ],
    },
    //DirectoryUploader Menu
    {
        path: '/directory',
        component: () => import('../views/directory/DirectoryUploader.vue'),
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                redirect: 'directory_list',
            },
            {
                path: 'directory_list',
                name: 'directory_list',
                component: () => import('../views/directory/DirectoryList.vue'),
            },
            {
                path: 'directory_list/upload',
                name: 'upload_directory',
                component: () => import('../views/directory/DirectoryUpload.vue'),
            },
        ],
    },
    //UserGroup Menu
    {
        path: '/usergroup',
        component: () => import('../views/usergroup/UserGroup.vue'),
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                redirect: 'list',
            },
            {
                path: 'list',
                name: 'UserGroupList',
                component: () => import('../views/usergroup/UserGroupList.vue'),
            },
            {
                path: 'create',
                name: 'UserGroupCreate',
                component: () => import('../views/usergroup/UserGroupInfo.vue'),
            },
            {
                path: ':id',
                name: 'UserGroupInfo',
                component: () => import('../views/usergroup/UserGroupInfo.vue'),
            },
            {
                path: ':id/user',
                name: 'UserGroupUserList',
                component: () => import('../views/usergroup/UserGroupUserList.vue'),
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        await store.dispatch('auth/isSignIn');
        if (!store.getters['auth/authenticated']) {
            next({
                path: '/login',
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
