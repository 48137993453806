/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import VuexPersist from 'vuex-persist';

//modules
import { auth } from './auth/index';

Vue.use(Vuex);

const vuexSessionStorage = new VuexPersist({
    key: 'IsoAdminStorage', // The key to store the state on in the storage provider.
    storage: window.sessionStorage, // or window.sessionStorage or localForage
    // Function that passes the state and returns the state with only the objects you want to store.
    reducer: (state: any) => ({
        auth: state.auth,
    }),
});

const vuexStore: StoreOptions<RootState> = {
    state: {
        version: '1.0.0', // a simple property
    },
    modules: {
        auth,
    },
    plugins: [vuexSessionStorage.plugin],
};

export default new Vuex.Store<RootState>(vuexStore);
